import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="SOSにほんご" />
    </Layout>
  )
}

export default IndexPage